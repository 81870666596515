<template>
  <v-app>
    <div>
      <form class="form" @submit.stop.prevent="formOnsubmit()">
        <!--begin: Wizard Step 1-->
        <div
          class="pb-5"
          data-wizard-type="step-content"
          data-wizard-state="current"
        >
          <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
            <span>
              {{ title }}
            </span>
          </h5>

          <b-form-group id="input-group-expired-date">
            <label for="input-expired-date"
              >Tanggal: </label
            >
            <v-menu
              v-model="menuDate1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <b-form-input
                  id="input-expired-date"
                  v-model="form.date"
                  label="Tanggal:"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></b-form-input>
              </template>
              <v-date-picker
                v-model="form.date"
                @input="menuDate1 = false"
              ></v-date-picker>
            </v-menu>
            <small class="text-danger">{{ error.date }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-user2"
            label="Pengguna:"
            label-for="input-user2"
          >
            <b-form-input
              id="input-user2"
              v-model="display.user_name"
              placeholder="Pilih Pengguna"
              readonly
              @click="$bvModal.show('modal-user2')"
            ></b-form-input>
            <small class="text-danger">{{ error.user_id }}</small>
          </b-form-group>

          <b-form-group id="input-group-description">
            <label for="input-description"
              >Keterangan: </label
            >
            <b-form-textarea
              id="input-description"
              v-model="form.description"
              placeholder="Keterangan"
              rows="4"
              max-rows="8"
            ></b-form-textarea>
            <small class="text-danger">{{ error.description }}</small>
          </b-form-group>
        </div>
        <!--end: Wizard Step 1-->

        <!--begin: Wizard Actions -->
        <div class="d-flex justify-content-between border-top pt-10">
          <div>
            <b-button type="submit" variant="primary">Simpan</b-button>
            <b-button
              type="button"
              class="ml-2"
              variant="default"
              @click="$router.push('/log-books/list')"
            >
              Batal
            </b-button>
          </div>
        </div>
        <!--end: Wizard Actions -->
      </form>
    </div>
    <ModalUser
      id="modal-user2"
      purpose="modal-user2"
      @chooseData="chooseDataUser"
      :filterProps="{role_id: ''}"
      title="Pilih Pengguna"
    />
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import ModalUser from "@/view/components/general/ModalUser2.vue";

export default {
  props: {
    form: Object,
    route: String,
    title: String,
    purpose: String,
  },
  components: {
    ModalUser,
  },
  data() {
    return {
      formData: new FormData(),
      error: {
        date: "",
        description: "",
        user_id: "",
      },
      menuDate1: false,
      menuDate2: false,
      display:{
          user_name: ''
      }
    };
  },
  methods: {
    chooseDataUser(value) {
      this.$bvModal.hide("modal-user2");
      this.form.user_id = value.id;
      this.display.user_name = value.name;
    },
    async formOnsubmit() {
      for (const [key, value] of Object.entries(this.form)) {
        this.formData.append(key, value);
      }

      // Make Request
      let response = await module.submit(this.formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        this.$router.push("/log-books");
      }
    },
  },
  watch: {
    form: function (newVal, oldVal) {
      if (this.purpose == "edit") {
        this.form = this.form;

      }
    },
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 230px !important;
  height: 230px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>